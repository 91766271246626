<template>
  <div>
    <!-- 购物车 -->
    <div v-show="shopShow == false" class="shopping_car">
      <div class="car" @click.stop="shopShow = true">
        <p>{{ placard.car_list.length }}</p>
      </div>
    </div>
    <div :class="shopShow ? 'shop_box' : 'shop_none_box'">
      <div class="title flex" style="justify-content: space-between; align-items: center">
        <h3 class="carTitle">已选媒体: {{ placard.car_list.length }}</h3>
        <button class="empty_but" v-show="placard.car_list.length != 0" @click="empty">清空已选媒体</button>
        <span class="close" @click="shopShow = false"></span>
      </div>
      <ul class="listbox" v-show="placard.car_list.length != 0">
        <li v-for="(  item, index  ) in  placard.car_list " :key="index">
          <!-- <img :src="item.logo" alt="" /> -->
          <div>
            <td_remark :text="item.title" />
          </div>
          <div class="flex" style="flex-wrap: wrap;">
            <p class="price">￥{{ item.member_guanggao_price[item.type] }}元/{{
      cycle_list[item.type]
    }}共{{ item.cycle }}期</p>
            <p class="price" v-if="item.check">{{ `[设计价格:￥${item.design_price}]` }}</p>
          </div>
          <span class="deleteIcon" @click.stop="detale(item, index)"></span>
        </li>
      </ul>
      <el-empty v-show="placard.car_list.length == 0" description="您还没有选择资源赶紧去看看心仪的资源吧"></el-empty>
      <div class="bottom_box">
        <div class="flex" style="justify-content: space-between; margin-bottom: 15px">
          <p style="font-size: 14px; color: #999">合计：</p>
          <p class="totalMoney">
            ￥<span>{{ total_money }}</span>
          </p>
        </div>
        <button class="put_but" @click="putFn">立即投放</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  props: {
    showCar: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    console.log(this.showCar)
  },
  watch: {
    'placard.car_list.length': {
      handler(newVal) {
        if (newVal != 0) {
          this.$emit('excelPush', this.placard.car_list)
          this.shopShow = true
        }
      },
      deep: true,
      immediate: true
    },
    // 'showCar': {
    //   handler(newVal, oldVal) {
    //     // console.log(newVal, oldVal)
    //     if (newVal == true) {
    //       this.shopShow = newVal
    //     }
    //   }
    // }
  },
  computed: {
    ...mapState(['placard']),
    ...mapMutations('placard', ['DELETE_LIST', 'CAR_LIST_RESET']),
    total_money: function () {
      var n = 0;
      this.placard.car_list.forEach((item, index) => {
        if (item.check) {
          n += (item.member_guanggao_price[item.type] * item.cycle) + parseInt(item.design_price)
        } else {
          n += item.member_guanggao_price[item.type] * item.cycle
        }
      })
      return n.toFixed(2)
    },
  },
  data() {
    return {
      shopShow: false,
      cycle_list: ['天', '周', '月', '次', '期']
    }
  },
  methods: {
    // 立即投放
    putFn() {
      this.$router.push({
        path: '/placard_user/placard_fill',
      })
    },
    detale(item) {
      this.$store.commit('DELETE_LIST', item.id)
      this.$emit('delete_item', item)
    },
    empty() {
      this.$store.commit('CAR_LIST_RESET')
      this.$emit('empty')
    },
  },
}
</script>

<style lang=scss scoped>
@import '@/scss/placard_media';
@import '@/scss/shopCar';
</style>