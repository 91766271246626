<template>
  <div>
    <div :class="path_arr == 'path_array1' ? '_screen_box_' : 'screen_media_box'">
      <ul class="flex ul1">
        <li :class="category_id == item.id ? 'active' : ''" v-for="( item, index ) in media_nav" :key="index"
            @click="nav_route(index)">
          {{ item.title }}
        </li>
      </ul>
      <div class="type2 flex" v-if="form.portal_list.length != 0">
        <p>{{
            category_id == 1 ? '广告类型' : category_id == 3 ? '刊物类型' : category_id == 4 ? '视听类型' : '平台类型'
          }}：</p>
        <ul class="flex ul2">
          <li :class="placard.date_form.portal_id == -1 ? 'active' : ''" @click="tab('portal_id', -1)">不限</li>
          <li :class="placard.date_form.portal_id == index ? 'active' : ''"
              v-for="( item, index ) in form.portal_list " :key="index" @click="tab('portal_id', index)">
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="type2 flex" v-if="form.classify_list.length != 0">
        <p>行业类型：</p>
        <ul class="flex ul2">
          <li :class="placard.date_form.classify_id == -1 ? 'active' : ''" @click="tab('classify_id', -1)">不限
          </li>
          <li :class="placard.date_form.classify_id == index ? 'active' : ''"
              v-for="( item, index ) in form.classify_list " :key="index" @click="tab('classify_id', index)">
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="type2 flex" v-if="form.area_list.length != 0">
        <p>覆盖区域：</p>
        <ul class="flex ul2">
          <li :class="placard.date_form.area_id == -1 ? 'active' : ''" @click="tab('area_id', -1)">不限</li>
          <li :class="placard.date_form.area_id == index ? 'active' : ''"
              v-for="( item, index ) in form.area_list " :key="index" @click="tab('area_id', index)">
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="type2 flex" v-if="form.price_range.length != 0">
        <p>价格区间：</p>
        <ul class="flex ul2">
          <li :class="placard.date_form.price_id == -1 ? 'active' : ''" @click="tab('price_id', -1)">不限</li>
          <li :class="placard.date_form.price_id == index ? 'active' : ''"
              v-for="( item, index ) in form.price_range " :key="index" @click="tab('price_id', index)">
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="type3 flex">
        <p>排序：</p>
        <ul class="flex ul2">
          <li @click="screenFn('')"
              :class="placard.date_form.order == '' && placard.date_form.sort == '' ? 'active' : ''">
            默认
          </li>
          <li @click="screenFn('member_guanggao_price')"
              :id="placard.date_form.sort == 'member_guanggao_price' && placard.date_form.order == 'asc' ? 'activeTrue' : placard.date_form.sort == 'member_guanggao_price' && placard.date_form.order == 'desc' ? 'activeFalse' : ''">
            价格
          </li>
        </ul>
      </div>
      <div class="type4 flex">
        <p class="type_title_n" v-if="category_id == 1">户外名称：</p>
        <p class="type_title_n" v-if="category_id == 2">平台名称：</p>
        <p class="type_title_n" v-if="category_id == 3">刊物名称：</p>
        <p class="type_title_n" v-if="category_id == 4">频道名称：</p>
        <el-input @keyup.enter.native="screen_confirm" v-model="placard.date_form.title"
                  placeholder="请输入广告名称："></el-input>
        <button class="sf_but" type="primary" @click="screen_confirm">筛选</button>
        <button class="sf_but2" type="primary" @click="reset_screen">重置筛选</button>
        <button v-if="userInfo" :class="placard.date_form.collect ? 'set_menu_show' : 'set_menu_none'" type="primary"
                @click="set_collect">我的收藏
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex'

export default {
  props: {
    category_id: Number,
    path_arr: String,
  },
  computed: {
    ...mapState(['placard']),
    ...mapMutations('placard', ['SET_DATE_FORM', 'SET_PLACARD_DATE', 'RESET_FILL_FORM']),

  },
  mounted() {
    //读取本地存储中用户的登录状态
    this.userInfo = localStorage.getItem('user_info')
    // console.log('用户状态',this.userInfo)
    let path_url_arr = {
      path_array1: ['/placard_user/user_outdoors_placard_media', '/placard_user/user_network_placard_media', '/placard_user/user_journal_placard_media', '/placard_user/user_tv_placard_media'],
      path_array2: ['/placard_media_index/outdoors', '/placard_media_index/network', '/placard_media_index/journal', '/placard_media_index/tv',],
    }
    this.placard.date_form.category_id = this.$props.category_id
    // 获取媒介分类
    this.curlGet('/api/guanggao_info/init').then(res => {
      if (res.data.code) {
        this.form.area_list = []
        this.form.portal_list = []
        this.form.price_range = []
        this.form.classify_list = []
        for (const key in res.data.data) {
          // 拿到导航类型
          this.media_nav.push({
            id: res.data.data[key].id,
            title: res.data.data[key].title,
          })
          // 拿到分类
          if (res.data.data[key].id == this.placard.date_form.category_id) {
            // 广告类型
            if (res.data.data[key].portal_list) {
              this.form.portal_list = res.data.data[key].portal_list
            }
            // 地区
            if (res.data.data[key].area_list) {
              this.form.area_list = res.data.data[key].area_list
            }
            // 行业类型
            if (res.data.data[key].price_range) {
              this.form.price_range = res.data.data[key].price_range
            }
            // 行业类型
            if (res.data.data[key].classify_list) {
              this.form.classify_list = res.data.data[key].classify_list
            }

          }
        }
        path_url_arr[this.path_arr].forEach((item, index) => {
          this.media_nav[index].path_url = item
        })
        this.screen_confirm()
        console.log(this.placard.date_form);
      }
    })
  },
  data() {
    return {
      media_nav: [],
      form: {
        // 地区
        area_list: [],
        // 平台类型
        portal_list: [],
        price_range: [],
        classify_list: [],
        priceStatus: [{
          id: 0,
          name: '默认价格'
        },
          {
            id: 'desc',
            name: '价格降序'
          },
          {
            id: 'asc',
            name: '价格升序'
          }
        ],
        // 可发链接
        linkList: [{
          id: 0,
          name: '否'
        }, {
          id: 1,
          name: '是'
        }],
      },
      userInfo: {}

    }
  },
  methods: {
    tab(index, id) {
      this.$store.commit('SET_DATE_FORM', {index: index, data: id})

      this.screen_confirm()
    },
    spec_tab(item, index) {
      // 重置之后再赋值
      this.placard.date_form.is_pub_image = 0
      this.placard.date_form.is_pub_weekend = ''
      this.placard.date_form.is_pub_url = ''
      this.placard.date_form.is_pub_video = 0
      this.placard.date_form.is_pub_weekend = 0
      this.placard.date_form.spce_title = item
      if (index !== -1) {
        this.placard.date_form[index] = 1
      }
      this.screen_confirm()
    },
    // 价格 电脑权重 移动权重筛选
    screenFn(item) {
      // 赋值给sort类型
      this.placard.date_form.sort = item
      if (this.placard.date_form.order == '' && item || this.placard.date_form.order == 'desc' && item) {
        this.placard.date_form.order = 'asc'
      } else if (this.placard.date_form.order == 'asc' && item) {
        this.placard.date_form.order = 'desc'
      } else if (!item) {
        // 如果用户点击的默认 清除order排序状态
        this.placard.date_form.order = ''
      }
      this.screen_confirm()
    },
    set_collect() {
      if (this.$store.state.placard.date_form.collect) {
        this.$store.state.placard.date_form.collect = 0
      } else {
        this.$store.state.placard.date_form.collect = 1
      }
      this.screen_confirm()
    },
    reset_screen(type) {
      this.$store.commit('RESET_FILL_FORM', {
        category_id: this.$props.category_id,
        portal_id: -1,
        classify_id: -1,
        area_id: -1,
        price_id: -1,
        collect: 0,
        sort: '',
        order: '',
      })
      if (type !== 2) {
        this.$emit('screen_confirm')
      }
    },
    screen_confirm() {
      this.$emit('screen_confirm')
    },
    nav_route(index) {
      this.placard.car_list = []
      this.reset_screen(2)
      console.log(this.placard.date_form);
      // 区分类型,如果是跳转路由,如果是2则重置筛选条件
      this.$router.push({
        path: this.media_nav[index].path_url
      })
    }
  },
}
</script>

<style lang=scss scoped>
@import '@/scss/user_media'
</style>