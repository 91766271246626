export function place_select_item(tableData, data) {
    const target_item = tableData[data.index]; // 直接通过索引获取目标项
    if (target_item) {
        const targetProp = target_item[data.prop]; // 获取目标属性
        if (Array.isArray(targetProp)) {
            const targetValue = targetProp[data.command]; // 直接通过索引获取目标值
            if (targetValue !== undefined) {
                target_item.type = data.command; // 设置 type 属性
            }
        }
    }
    return tableData;
}